import { GetClassTicket } from '@/api/classTicket'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'

export const useAllData = () => {
  const { shopId } = useShop()
  const { fetchAll } = useFetch()

  const getAllClassTicket = async () => {
    let result = []
    await fetchAll(GetClassTicket, { shopId: shopId.value }, (res) => {
      result = res
    })
    return result
  }

  return { getAllClassTicket }
}
