<template>
  <div>
    <el-form-item
      v-if="useBindClassTickets"
      label="綁定堂票"
      prop="classTickets"
    >
      <el-select
        v-model="syncClassTickets"
        popper-class="select-popper"
        placeholder="選擇堂票"
        multiple
        collapse-tags
        @visible-change="showSelector = true"
      >
        <el-option
          v-for="classTicket in displayClassTicketOptions"
          :key="classTicket.value"
          :label="classTicket.label"
          :value="classTicket.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item v-if="syncClassTickets.length" label="此服務項目僅供堂票預約">
      <el-switch
        v-model="syncRequiredClassTicket"
        inactive-text="否"
        active-text="是"
      />
    </el-form-item>

    <ClassTicketAdvanceSelect
      v-if="showSelector"
      :data="classTicketList"
      :selectedData="syncClassTickets"
      :disabledExp="true"
      multiple
      @confirm="onSelected"
      @close="showSelector = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import ClassTicketAdvanceSelect from '@/components/Select/ClassTicketAdvanceSelect.vue'
import { map } from 'lodash'

export default defineComponent({
  name: 'BindClassTicketBlock',
  components: { ClassTicketAdvanceSelect },
  props: {
    classTickets: { type: Array, default: () => ([]) },
    requiredClassTicket: Boolean,
    useBindClassTickets: Boolean,
    classTicketList: { type: Array, default: () => ([]) },
  },
  emits: ['update:value'],
  setup (props, { emit }) {
    const syncClassTickets = useVModel(props, 'classTickets', emit)
    const syncRequiredClassTicket = useVModel(props, 'requiredClassTicket', emit)
    const showSelector = ref(false)

    const onSelected = (classTicketIds) => {
      syncClassTickets.value = classTicketIds
    }

    const displayClassTicketOptions = computed(() => {
      return map(props.classTicketList, i => ({
        label: i.name,
        value: i.id,
      }))
    })

    return { syncClassTickets, showSelector, displayClassTicketOptions, onSelected, syncRequiredClassTicket }
  },
})

</script>

<style lang="postcss" scoped>

</style>
