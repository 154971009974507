<template>
  <el-dialog title="子項目設定" :visible="true" :close-on-click-modal="false" @close="$emit('close')">
    <el-form ref="form" :model="formData" label-position="top" :rules="formRules">
      <el-form-item label="圖片" prop="image">
        <UploadButton
          cyUploadBtn="service-img-upload-btn"
          cyUploadedImg="service-img-uploaded"
          :img="formData.Image"
          :isAvatar="true"
          @change="loadImg"
        />
      </el-form-item>
      <el-form-item label="名稱" prop="name">
        <el-input v-model="formData.name" :maxlength="55" show-word-limit data-cy="name-input" placeholder="名稱" />
      </el-form-item>
      <el-form-item label="服務敘述" prop="description">
        <el-input
          v-model="formData.description"
          data-cy="description-input"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 6}"
          maxlength="200"
          show-word-limit
          placeholder="請輸入服務敘述"
        />
      </el-form-item>
      <el-form-item label="實際預約時間(分鐘)" prop="bookingTime">
        <div class="flex flex-col">
          <el-input
            v-model="formData.bookingTime"
            data-cy="service-time-input"
            placeholder="請輸入實際預約時間"
          />
          <el-checkbox
            v-model="formData.hideTime"
            data-cy="hide-service-time"
          >
            隱藏實際預約時間
          </el-checkbox>
        </div>
      </el-form-item>
      <el-form-item
        v-if="formData.hideTime"
        data-cy="show-service-time-input"
        label="顯示預約時間"
        prop="showTime"
      >
        <el-input v-model="formData.showTime" placeholder="自定義 只允許數字  (0 視為不隱藏，將顯示實際預約時間)" />
      </el-form-item>
      <el-form-item label="實際價格" prop="price">
        <div class="flex flex-col">
          <el-input v-model="formData.price" data-cy="service-price-input" placeholder="請輸入實際價格" />
          <el-checkbox v-model="formData.hidePrice" data-cy="hide-service-price">隱藏實際價格</el-checkbox>
        </div>
      </el-form-item>
      <el-form-item v-if="formData.hidePrice" label="顯示價格" prop="showPrice">
        <el-input v-model="formData.showPrice" :maxlength="40" show-word-limit data-cy="show-service-price-input" placeholder="自定義 範例: 500元起跳 (0 視為不隱藏，將顯示實際價格)" />
      </el-form-item>
      <el-form-item label="排序" prop="order">
        <el-input v-model="formData.order" data-cy="order-input" placeholder="請輸入排序" />
      </el-form-item>

      <el-form-item v-if="mainService.enableAppointmentUnit" label="綁定車輛車次" prop="unit">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>綁定車輛車次</p>
            <el-tooltip placement="right">
              <div slot="content">
                <ol style="list-style: decimal;padding-left: 16px;">
                  <li>僅可選擇服務項目所綁定的車次，如欲綁定其他<br>車輛車次請先至服務項目中新增綁定</li>
                  <li>若子項目未綁定車次，將視為綁定服務項目所綁<br>定的車次</li>
                </ol>
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <!-- <ServiceUnitSelect data-cy="unit-binding-select" :model.sync="formData.serviceUnits" multiple :showAll="true" /> -->
        <el-select v-model="formData.serviceUnits" value-key="id" placeholder="請選擇服務車次" clearable multiple>
          <el-option
            v-for="unit in mainService.serviceUnits"
            :key="unit.id"
            :label="unit.name"
            :value="unit"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="mainService.enableResourceItem" label="綁定設備" prop="resource">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>綁定設備</p>
            <el-tooltip placement="right">
              <div slot="content">
                <ol style="list-style: decimal;padding-left: 16px;">
                  <li>僅可選擇服務項目所綁定的服務設備，如欲綁定其他<br>服務設備請先至服務項目中新增綁定</li>
                  <li>若子項目未綁定服務設備，將視為綁定服務項目所綁<br>定的服務設備</li>
                </ol>
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <ResourceUnitSelect data-cy="unit-binding-select" :model.sync="formData.resourceItems" :data="resourceItemList" multiple :showAll="true" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button data-cy="dialog-cancel-btn" plain @click="$emit('close')">取消</el-button>
      <el-button data-cy="dialog-confirm-btn" type="primary" @click="handleConfirm">確認</el-button>
    </div>

    <ImageCropper v-if="uploadDialog" :image="formData.Image" @uploaded="getImage" @close="uploadDialog = false" />
  </el-dialog>
</template>

<script>
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
// import { UpdateSubService } from '@/api/service'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { map, cloneDeep, isEmpty, find, get } from 'lodash'

export default defineComponent({
  name: 'CreateChildServiceDialog',
  components: {
    ImageCropper,
    UploadButton,
    // ServiceUnitSelect,
    ResourceUnitSelect,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    service: {
      type: [Object, String],
      default: () => ({}),
    },
    mainService: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    selectService: null,
    // availableUnitList: [],
    uploadDialog: false,
    formData: {
      Image: null,
      name: null,
      description: null,
      hidePrice: false,
      hideTime: false,
      price: null,
      showPrice: '',
      showTime: '',
      bookingTime: null,
      order: 100,
      allAppointmentUnit: false,
      serviceUnits: null,
      resourceItems: null,
      enableAppointmentUnit: false,
      enableResourceItem: false,
    },
    formRules: {
      name: noEmptyRules(),
      description: noEmptyRules(),
      price: [isDigitRules(), noEmptyRules(), rangeRules()],
      // showPrice: [isDigitRules(false)],
      showTime: [isDigitRules(false), rangeRules()],
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    resourceItemList () {
      return get(this.mainService, 'resourceItems')
    },
  },
  mounted () {
    // this.availableUnitList = this.mainService.serviceUnits
    if (!isEmpty(this.service)) this.syncData()
    if (isEmpty(this.service)) this.setDefaultData()
    const units = this.removeUnbindUnit(this.mainService.serviceUnits, this.formData.serviceUnits)
    this.formData.serviceUnits = units
  },
  methods: {
    getImage (data) {
      this.formData.Image = data
      // this.avatarChanged = true
      this.uploadDialog = false
    },
    loadImg (img) {
      this.formData.Image = img
      this.uploadDialog = true
    },
    async handleConfirm () {
      this.loading = true
      if (this.type === 'create') await this.createSubService()
      if (this.type === 'edit') await this.updateSubService()
      this.loading = false
    },
    //= > 創建服務
    async createSubService () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      // this.$message.success('新增服務成功!')
      // if (!this.formData.serviceUnits || isEmpty(this.formData.serviceUnits)) {
      //   this.formData.serviceUnits = this.mainService.serviceUnits
      // }
      this.$emit('created', this.formData)
      this.$emit('close')
    },

    //= > 更新服務
    async updateSubService () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      // this.$message.success('更新服務成功!')
      // if (!this.formData.serviceUnits || isEmpty(this.formData.serviceUnits)) {
      //   this.formData.serviceUnits = this.mainService.serviceUnits
      // }
      this.$emit('edited', this.formData)
      this.$emit('close')
    },

    syncData () {
      const res = cloneDeep(this.service)
      this.formData.name = res.name
      this.formData.Image = res.Image
      if (res.id) this.formData.id = res.id
      this.formData.description = res.description
      this.formData.isPublic = res.isPublic
      this.formData.order = res.order
      this.formData.price = res.price
      this.formData.bookingTime = res.bookingTime
      this.formData.serviceUnits = res.serviceUnits
      this.formData.resourceItems = res.resourceItems
      if (res.showPrice && res.showPrice !== '0') {
        this.formData.hidePrice = true
        this.formData.showPrice = res.showPrice
      }

      if (res.showTime && res.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = res.showTime
      }
      this.formData.enableSubService = res.enableSubService
      this.formData.enableSubServiceClientHide = res.enableSubServiceClientHide
      this.formData.enableAppointmentUnit = res.enableAppointmentUnit
      this.formData.enableResourceItem = res.enableResourceItem
    },

    setDefaultData () {
      const data = cloneDeep(this.mainService)
      this.formData.price = data.price
      this.formData.bookingTime = data.bookingTime
      // this.availableUnitList = data.serviceUnits
      if (data.hidePrice && data.showPrice && data.showPrice !== '0') {
        this.formData.hidePrice = true
        this.formData.showPrice = data.showPrice
      }
      if (data.hideTime && data.showTime && data.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = data.showTime
      }
      this.formData.enableAppointmentUnit = data.enableAppointmentUnit
      this.formData.enableResourceItem = data.enableResourceItem
    },
    removeUnbindUnit (availableUnitList, data) {
      if (!data) return []
      return data.filter(i => find(availableUnitList, { id: i.id }))
      // if (this.formData.serviceUnits) {
      //   this.formData.serviceUnits = this.formData.serviceUnits.filter(i => find(this.availableUnitList, { id: i.id }))
      // }
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-select {
  @apply h-full;
}
::v-deep .el-select .el-input {
  @apply h-full;
}
::v-deep .el-tag--info {
  @apply h-full break-all;
  white-space: break-spaces;
}
::v-deep .el-select__tags {
  @apply flex flex-wrap;
}
</style>
