
import { admin } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/appointmentCategory`
}

//= > 創建服務類別
export const CreateServiceCategory = async ({
  shopId,
  name,
  image,
  order,
  appointmentServices,
  isPublic,
}) => {
  const res = await admin({
    method: 'post',
    url: baseUrl(shopId),
    data: {
      name,
      image,
      order,
      isPublic,
      appointmentServices,
    },
  })

  return res.data
}

//= > 取得服務類別
export const GetServiceCategory = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

//= > 取得服務類別總數
export const GetServiceCategoryCount = async ({
  shopId,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })

  return res
}

//= > 取得指定服務類別
export const FindServiceCategory = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新服務類別
export const UpdateServiceCategory = async ({
  shopId,
  id,
  name,
  image,
  order,
  appointmentServices,
  isPublic,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
      image,
      order,
      appointmentServices,
      isPublic,
    },
  })

  return res.data
}

//= > 刪除服務類別
export const DeleteServiceCategory = async ({
  shopId,
  id,
}) => {
  const res = admin({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })
  return res.data
}
