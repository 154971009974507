<template>
  <div class="create-reservation-service">
    <div class="card-container wrapper">
      <div class="font-bold text-lg text-primary-100" style="margin-bottom: 16px">服務地點設定</div>

      <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
        <el-form-item label="圖片" prop="image">
          <UploadButton
            cyUploadBtn="service-img-upload-btn"
            cyUploadedImg="service-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </el-form-item>

        <el-form-item label="公開顯示">
          <el-switch
            v-model="formData.isPublic"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>
        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" :maxlength="55" show-word-limit data-cy="name-input" placeholder="名稱" />
        </el-form-item>
        <el-form-item label="服務敘述" prop="description">
          <el-input
            v-model="formData.description"
            data-cy="description-input"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            maxlength="500"
            show-word-limit
            placeholder="請輸入服務敘述"
          />
        </el-form-item>
        <el-form-item label="實際預約時間(分鐘)" prop="bookingTime">
          <div class="flex flex-col">
            <el-input
              v-model="formData.bookingTime"
              data-cy="service-time-input"
              placeholder="請輸入實際預約時間"
            />
            <el-checkbox
              v-model="formData.hideTime"
              data-cy="hide-service-time"
            >
              隱藏實際預約時間
            </el-checkbox>
          </div>
        </el-form-item>
        <el-form-item
          v-if="formData.hideTime"
          data-cy="show-service-time-input"
          label="顯示預約時間"
          prop="showTime"
        >
          <el-input v-model="formData.showTime" placeholder="自定義 只允許數字  (0 視為不隱藏，將顯示實際預約時間)" />
        </el-form-item>
<!--        <el-form-item label="實際價格" prop="price">-->
<!--          <div class="flex flex-col">-->
<!--            <el-input v-model="formData.price" data-cy="service-price-input" placeholder="請輸入實際價格" />-->
<!--            <el-checkbox v-model="formData.hidePrice" data-cy="hide-service-price">隱藏實際價格</el-checkbox>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item v-if="formData.hidePrice" label="顯示價格" prop="showPrice">
          <el-input v-model="formData.showPrice" :maxlength="40" show-word-limit data-cy="show-service-price-input" placeholder="自定義 範例: 500元起跳 (0 視為不隱藏，將顯示實際價格)" />
        </el-form-item>
        <el-form-item label="排序" prop="order">
          <el-input v-model="formData.order" clearable data-cy="order-input" placeholder="請輸入排序" />
        </el-form-item>
        <el-form-item label="接送地址" prop="address">
          <el-input v-model="formData.address" clearable data-cy="order-input" placeholder="請輸入服務地址" />
        </el-form-item>
        <el-form-item label="綁定服務類別" prop="category">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>綁定服務類別</p>
              <el-tooltip placement="right">
                <div slot="content">
                  說明：需綁定服務類別，服務項目才會出現在顧客端的預約選單內。
                </div>
                <span class="material-icons">help_outline</span>
              </el-tooltip>
            </div>
          </template>
          <ServiceCategorySelect data-cy="category-binding-select" :model.sync="formData.serviceCategorys" multiple :showAll="true" />
        </el-form-item>
        <el-form-item v-if="checkResource" label="服務性質" prop="type">
          <el-select
            v-model="formData.type"
            value-key="id"
            placeholder="請選擇"
            no-data-text="暫無數據"
            @change="changeType"
          >
            <el-option
              v-for="item in serviceTypeConfigList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <BindClassTicketBlock
          :classTickets.sync="formData.classTickets"
          :requiredClassTicket.sync="formData.requiredClassTicket"
          :useBindClassTickets="useBindClassTickets"
          :classTicketList="classTicketList"
        />

        <el-form-item v-if="formData.enableAppointmentUnit" label="綁定車輛車次" prop="unit">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>綁定車輛車次</p>
              <el-tooltip placement="right">
                <div slot="content">
                  增加服務項目所綁定的車次後，需<br>
                  依據需求自行調整子項目服務車次<br>
                  ；而移除服務項目所綁定的服務車<br>
                  次後，系統將會連帶移除子項目中<br>
                  的該服務車次
                </div>
                <span class="material-icons">help_outline</span>
              </el-tooltip>
            </div>
          </template>
          <ServiceUnitSelect data-cy="unit-binding-select" :model.sync="formData.serviceUnits" multiple :showAll="true" />
        </el-form-item>
        <el-form-item v-if="formData.enableResourceItem && checkResource" label="綁定設備" prop="resource">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>綁定設備 <span v-if="formData.type === 'humanAndResourceService'" class="text-sub text-gray-60">( 綁定設備不會顯示在顧客的預約流程。預約時僅需選擇服務車次 )</span></p>
              <el-tooltip placement="right">
                <div slot="content">
                  <ol style="list-style: decimal;padding-left: 16px;">
                    <li>
                      有綁定設備的服務項目僅提供<br>
                      「單人預約」，且顧客端無法<br>
                      選擇當日其他服務項目
                    </li>
                    <li>
                      增加服務項目所綁定的服務設<br>
                      備後，需依據需求自行調整子<br>
                      項目服務設備；而移除服務項<br>
                      目所綁定的服務設備後，系統<br>
                      將會連帶移除子項目中的該服<br>
                      務設備
                    </li>
                  </ol>
                </div>
                <span class="material-icons">help_outline</span>
              </el-tooltip>
            </div>
          </template>
          <ResourceUnitSelect data-cy="unit-binding-select" :model.sync="formData.resourceItems" multiple :showAll="true" />
        </el-form-item>
        <el-form-item label="附加服務">
          <AttachServiceSelect data-cy="attach-service-binding-select" :model.sync="formData.attachService" multiple :showAll="true" />
        </el-form-item>
        <div v-if="formData.enableResourceItem && formData.enableAppointmentUnit && formData.attachService.length > 0 && checkResource" class="attachServiceBindResource">
          <p class="font-medium text-gray-100 mb-[8px]">附加服務是否使用綁定的設備？</p>
          <div v-for="item in formData.attachService" :key="item.id" class="flex items-center mb-[8px]">
            <p class="w-[120px] mr-[12px] text-gray-100">{{ item.name }}</p>
            <el-switch
              v-model="item.enableResourceItemBooking"
              active-text="是"
              inactive-text="否"
            />
          </div>
        </div>

        <div v-if="useSubService">
          <hr style="margin-bottom: 22px">

          <el-form-item label="是否要啟用子項目">
            <el-switch
              v-model="formData.enableSubService"
              active-text="是"
              inactive-text="否"
            />
          </el-form-item>
          <el-form-item v-if="formData.enableSubService">
            <template slot="label">
              <FormItemTooltipLabel
                label="是否開放客人選擇子項目"
                :tooltipWidth="200"
                class="title"
              >
                是：客人預約時可選擇子項目<br>
                否：客人預約時，將依排序自動帶入子項目
              </FormItemTooltipLabel>
            </template>
            <el-switch
              v-model="formData.enableSubServiceClientHide"
              active-text="是"
              inactive-text="否"
            />
          </el-form-item>
        </div>
      </el-form>

      <ChildServiceTable
        v-if="formData.enableSubService && useSubService"
        :data="formData.AppointmentSubServices"
        :mainService="formData"
        @update="subServicesUpdate"
      />
    </div>

    <ImageCropper v-if="uploadDialog" :image="formData.img" @uploaded="getImage" @close="uploadDialog = false" />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({name: 'ServiceSetting'})"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref, reactive } from 'vue'
import imageMixin from '@/mixin/image'
import ChildServiceTable from './components/ChildServiceTable.vue'
import ServiceCategorySelect from '@/components/Select/ServiceCategorySelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import BindClassTicketBlock from './components/BindClassTicketBlock.vue'
// api
import { CreateService, UpdateService, FindService } from '@/api/service'
// utils
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import { map, find, omit, get } from 'lodash'
import formUtils from '@/utils/form'

import { useAllData } from '@/use/useAllData'
import { usePermissions } from '@/use/permissions'
import { serviceTypeConfig } from '@/config/reservation'

export default {
  name: 'CreateReservationService',
  components: {
    UploadButton,
    ImageCropper,
    ChildServiceTable,
    ServiceCategorySelect,
    AttachServiceSelect,
    ServiceUnitSelect,
    ResourceUnitSelect,
    FormItemTooltipLabel,
    BindClassTicketBlock,
  },
  mixins: [imageMixin],
  setup (props) {
    const { getAllClassTicket } = useAllData()
    const { checkActionList, checkAction } = usePermissions()
    const useBindClassTickets = computed(() => checkActionList(['admin.appointmentService.bindClassTicket', 'admin.classTicket.find'], 'union'))
    const classTicketList = ref([])
    const formRef = ref(null)
    const formData = reactive({
      img: null,
      avatar: null,
      name: '',
      description: '',
      hidePrice: false,
      hideTime: false,
      price: 0,
      showPrice: '',
      showTime: '',
      bookingTime: '',
      order: 100,
      isPublic: true,
      attachService: [],
      serviceCategorys: [],
      serviceUnits: [],
      resourceItems: [],
      AppointmentSubServices: [],
      enableSubService: false,
      enableSubServiceClientHide: false,
      classTickets: [],
      requiredClassTicket: false,
      type: 'humanService',
      enableAppointmentUnit: true,
      enableResourceItem: false,
      address: ''
    })
    const serviceTypeConfigList = computed(() => {
      const omitList = []
      if (!checkAction('admin.resourceItem.page')) omitList.push('resourceService', 'humanAndResourceService')
      return omit(serviceTypeConfig, omitList)
    })
    const checkResource = computed(() => {
      return checkAction('admin.resourceItem.page')
    })
    const show = reactive({
      classTicketSelect: false,
    })
    const changeType = (data) => {
      switch (data) {
      case 'humanService':
        formData.enableAppointmentUnit = true
        formData.enableResourceItem = false
        break
      case 'resourceService':
        formData.enableAppointmentUnit = false
        formData.enableResourceItem = true
        break
      case 'humanAndResourceService':
        formData.enableAppointmentUnit = true
        formData.enableResourceItem = true
        break
      default:
        break
      }
    }

    onMounted(async () => {
      if (useBindClassTickets.value) classTicketList.value = await getAllClassTicket()
    })

    return { classTicketList, useBindClassTickets, getAllClassTicket, show, formData, formRef, changeType, serviceTypeConfigList, checkResource }
  },
  data: () => ({
    imgSize: 90,
    loading: false,
    uploadDialog: false,
    avatarChanged: false,
    subServiceList: [],

    formRules: {
      name: noEmptyRules(),
      description: noEmptyRules(),
      price: [isDigitRules(), noEmptyRules(), rangeRules()],
      // showPrice: [isDigitRules(false)],
      showTime: [isDigitRules(false), rangeRules()],
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
      type: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userFeatures',
      'userPlanFeature',
    ]),
    useSubService () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.appointmentSubService.edit')
    },
    serviceId () {
      return this.$route.params.serviceId
    },
  },
  async mounted () {
    if (this.serviceId) await this.findService()
  },
  methods: {
    subServicesUpdate (data) {
      this.subServiceList = data
    },
    removeUnbindUnit () {
      this.subServiceList.forEach(service => {
        if (service.appointmentUnits) {
          const res = service.appointmentUnits.filter(i => find(get(this.formData, 'serviceUnits'), { id: i }))
          service.appointmentUnits = res
        }
      })
    },
    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },
    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },
    async handleConfirm () {
      this.loading = true
      if (!await formUtils.checkForm(this.formRef)) return
      if (this.serviceId) await this.updateService()
      else if (!this.serviceId) await this.createService()
      this.loading = false
    },
    //= > 創建服務
    async createService () {
      const form = this.formData
      if (!form.hidePrice) form.showPrice = '0'
      if (!form.hideTime) form.showTime = '0'
      this.removeUnbindUnit()
      const [, err] = await CreateService({
        shopId: this.shop,
        name: form.name,
        image: (!form.avatar) ? undefined : this.formData.avatar.id,
        description: form.description,
        price: form.price,
        showPrice: (form.showPrice === '') ? '0' : form.showPrice,
        showTime: (form.showTime === '') ? 0 : form.showTime,
        bookingTime: form.bookingTime,
        order: form.order,
        isPublic: form.isPublic,
        appointmentCategories: map(this.formData.serviceCategorys, 'id'),
        appointmentServiceAttaches: map(this.formData.attachService, 'id'),
        appointmentUnits: map(this.formData.serviceUnits, 'id'),
        resourceItems: map(this.formData.resourceItems, 'id'),
        enableSubService: this.useSubService ? this.formData.enableSubService : undefined,
        enableSubServiceClientHide: this.useSubService ? !this.formData.enableSubServiceClientHide : undefined,
        appointmentSubServices: this.useSubService ? this.subServiceList : undefined,
        requiredClassTicket: this.formData.requiredClassTicket,
        classTickets: this.formData.classTickets,
        enableAppointmentUnit: this.formData.enableAppointmentUnit,
        enableResourceItem: this.formData.enableResourceItem,
        attachServiceSettings: this.formData.attachService.map(item => {
          return {
            appointmentServiceAttachId: item.id,
            enableResourceItemBooking: item.enableResourceItemBooking,
          }
        }),
      })
      if (err) {
        this.$message.error(err)
        return
      }

      this.$message.success('新增服務成功!')
      this.$router.push({ name: 'ServiceSetting' })
    },

    //= > 更新服務
    async updateService () {
      console.log('update')
      const form = this.formData
      if (!form.hidePrice) form.showPrice = '0'
      if (!form.hideTime) form.showTime = '0'
      this.removeUnbindUnit()
      const [, err] = await UpdateService({
        shopId: this.shop,
        id: this.serviceId,
        name: form.name,
        image: (this.avatarChanged) ? this.formData.avatar.id : undefined,
        description: form.description,
        price: form.price,
        showPrice: (form.showPrice === '') ? '0' : form.showPrice,
        showTime: (form.showTime === '') ? 0 : form.showTime,
        bookingTime: form.bookingTime,
        address: form.address,
        order: form.order,
        isPublic: form.isPublic,
        appointmentCategories: map(this.formData.serviceCategorys, 'id'),
        appointmentServiceAttaches: map(this.formData.attachService, 'id'),
        appointmentUnits: map(this.formData.serviceUnits, 'id'),
        resourceItems: map(this.formData.resourceItems, 'id'),
        enableSubService: this.useSubService ? this.formData.enableSubService : undefined,
        enableSubServiceClientHide: this.useSubService ? !this.formData.enableSubServiceClientHide : undefined,
        appointmentSubServices: this.useSubService ? this.subServiceList : undefined,
        requiredClassTicket: this.formData.requiredClassTicket,
        classTickets: this.formData.classTickets,
        enableAppointmentUnit: this.formData.enableAppointmentUnit,
        enableResourceItem: this.formData.enableResourceItem,
        attachServiceSettings: this.formData.attachService.map(item => {
          return {
            appointmentServiceAttachId: item.id,
            enableResourceItemBooking: item.enableResourceItemBooking,
          }
        }),
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新服務成功!')
      this.$router.push({ name: 'ServiceSetting' })
    },

    //= > 查找特定服務
    async findService () {
      const [res, err] = await FindService({
        shopId: this.shop,
        id: this.serviceId,
      })
      if (err) {
        this.$message.error(err)
        return
      }

      this.syncData(res)
    },
    syncData (res) {
      this.formData.avatar = res.Image
      this.formData.name = res.name
      this.formData.description = res.description
      this.formData.isPublic = res.isPublic
      this.formData.order = res.order
      this.formData.price = res.price
      this.formData.bookingTime = res.bookingTime
      this.formData.attachService = res.AppointmentServiceAttaches.filter(item => !item.isRemove)
      this.formData.serviceUnits = res.AppointmentUnits.filter(item => !item.isRemove)
      this.formData.resourceItems = res.ResourceItems
      this.formData.serviceCategorys = res.AppointmentCategories.filter(item => !item.isRemove)
      this.formData.AppointmentSubServices = res.AppointmentSubServices
      this.formData.classTickets = map(res.ClassTickets, 'id')
      this.formData.requiredClassTicket = res.requiredClassTicket
      this.formData.address = res.address

      if (res.showPrice && res.showPrice !== '0') {
        this.formData.hidePrice = true
        this.formData.showPrice = res.showPrice
      }
      if (res.showTime && res.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = res.showTime
      }
      this.formData.enableSubService = res.enableSubService
      this.formData.enableSubServiceClientHide = !res.enableSubServiceClientHide
      this.formData.enableAppointmentUnit = res.enableAppointmentUnit
      this.formData.enableResourceItem = res.enableResourceItem
      if (res.enableAppointmentUnit && res.enableResourceItem) {
        this.formData.type = 'humanAndResourceService'
      } else if (res.enableAppointmentUnit) {
        this.formData.type = 'humanService'
      } else if (res.enableResourceItem) {
        this.formData.type = 'resourceService'
      }

      this.formData.attachService = this.formData.attachService.map(item => {
        const matchingItem = res.attachServiceSettings.find(mergeItem => mergeItem.appointmentServiceAttachId === item.id)
        if (matchingItem) {
          return {
            ...item,
            appointmentServiceAttachId: matchingItem.appointmentServiceAttachId,
            enableResourceItemBooking: matchingItem.enableResourceItemBooking,
          }
        } else {
          return item
        }
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}
.attachServiceBindResource {
  @apply rounded-[8px] bg-[#F5F7FA] p-[20px] max-w-[560px] mb-[20px]
}
</style>
